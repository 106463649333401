<template>
  <van-pull-refresh
    v-model="isLoading"
    success-text="刷新成功"
    @refresh="onRefresh"
  >
  <div class="billList">
    <!-- 账单页 -->
    <div class="hasBilllist" v-if="list.length > 0">
      <div class="hasBill-content">
        <div class="content-item" v-for="item in list" :key="item.id">
          <div class="item-top">
            <span class="appName">{{ item.loanName }}</span>
            <span class="loanDate">借款日:{{ item.gmtCreatedStr }}</span>
            <span class="backing">还款中</span>
          </div>
          <div class="item-bottom">
            <p class="item-bottom-left">
              <span class="loanMoney">{{ item.planAmt }}</span>
              <span class="nowbill">本期账单</span>
            </p>
            <p class="item-bottom-middle">
              <span class="nowStatus" v-if="item.status == 'UNCURRENT'"
                >未到期</span
              >
              <span class="nowStatus" v-if="item.status == 'BACKING'"
                >待还款</span
              >
              <span class="nowStatus2" v-if="item.status == 'OVERDUED'"
                >已逾期</span
              >
              <span class="backDate">{{ item.planDateStr }}</span>
            </p>
            <button class="immediaBack" @click="goBackurl(item)">
              立即还款
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 无账单 -->
    <div class="noBilllist" v-else>
      <div class="nobill-content">
        <img src="./img/bill-bgc.png" alt="" />
        <p>目前还没有账单哦</p>
        <!-- <button v-if="!userToken" @click="gotoLogin">去登录</button> 
        <button v-else @click="gotoLoan">去借款</button> -->
      </div>
    </div>
  </div>
  </van-pull-refresh>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import API from "../../server/api";
import APP from "../../utils/App";
import { routerTag, GetUrlParams } from "../../utils/index";
import Moment from "moment";
export default {
  name: "Bill",
  setup() {
    // 定义当前页面的state
    const state = reactive({
      // isLogin: false,
      list: [],
      isLoading: false,
      hasNextPage: false,
      desc: "目前还没有账单哦，去借一笔",
    });
    // APP.Alert(`${routerTag}${loan}`)

    // 初始化数据
    const init = async () => {
      const result = await API.bill({});
      console.log(result, 999);
      if (result.billList) {
        state.list = result.billList;
      }
      //  console.log(state.list,997);
    };
   // 刷新
    const onRefresh = () => {
      state.isLoading = false;
    };
    // 页面加载
    onMounted(() => {
      window.TDAPP.onEvent("账单页-进入");
      const urlParam = GetUrlParams();
      const decodeList = decodeURI(urlParam.loanModelList);
      state.list = JSON.parse(decodeList);
      console.log(state.list, "哈哈哈哈");
    });
    //立即还款
    const goBackurl = (item) => {
      APP.JUMP_TO(`${routerTag}billDetail?loanNo=${item.loanNo}`);
    };
    return {
      ...toRefs(state),
      goBackurl,
      onRefresh
    };
  },
};
</script>

<style lang="less" src='./index.less' scoped></style>
